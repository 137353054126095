<template>
  <section class="home">
    <main-nav ref="mainNav"></main-nav>

    <div class="su-body">
      <div
        class="su-slide su-section-one"
        v-waypoint="{ active: true, callback: onWaypoint1, options: intersectionOptions }"
      >
        <vimeo-player
          v-if="display === 'xs'"
          ref="player"
          :options="{
            responsive: true,
            muted: true,
            controls: false,
          }"
          @ready="onReady"
          video-id="837584629"
          class="video-player player-hero"
        >
        </vimeo-player>

        <vimeo-player
          v-else
          ref="player"
          :options="{
            responsive: true,
            muted: true,
            controls: false,
          }"
          @ready="onReady"
          video-id="837574946"
          class="video-player player-hero"
        >
        </vimeo-player>

        <div class="video-player-button">
          <label for="volumeToggle1" class="unmuted toggle-sound" :class="{ sound_mute: mute }">
            <div class="tooltip--left sound" data-tooltip="Turn On/Off Sound">
              <div class="sound--icon fa fa-volume-off"></div>
              <div class="sound--wave sound--wave_one"></div>
              <div class="sound--wave sound--wave_two"></div>
              <div class="sound--wave sound--wave_three"></div>
            </div>
          </label>
          <input id="volumeToggle1" type="checkbox" @click="muteToggle()" v-model="$data.mute" />
        </div>
      </div>

      <div
        class="m-0 p-0"
        @mouseover="$refs.mainNav.setFocus(1)"
        @mouseleave="$refs.mainNav.setNotFocus(1)"
        id="about-us"
      >
        <div class="su-section-two container">
          <div class="row">
            <div class="col-1 void-column"></div>

            <div class="col-12 col-md-11">
              <div class="row">
                <div class="col-12">
                  <h1>
                    SHOWUP designs and produces entertainment, provides creative services and
                    supplies talent for clients around the globe.
                  </h1>
                </div>

                <div class="col-12 mb-5">
                  <h3>
                    We bring a world of expertise, experience and<br />
                    innovation to every stage.
                  </h3>
                  <h3>
                    Entertainment is more than our business, it's our<br />
                    calling. We are inspired every day by the<br />
                    opportunity to create moments of magic that will<br />
                    stay with audiences long after the show is over.
                  </h3>
                </div>

                <div class="col-12">
                  <a href="/about" class="su-link">
                    <span class="addText">Where did we<br />show up from?</span>
                    <img class="icon" src="/assets/img/about_us_cta.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="su-section-three">
          <div class="w-100">
            <div class="w-auto" @mouseover="lockMarquee1(true)" @mouseleave="lockMarquee1(false)">
              <vue-marquee-slider
                id="marquee-slider-1"
                style="margin-bottom: calc(-4rem - 50px)"
                class="marquee-slider"
                :space="50"
                :speed="100000"
                :width="400"
                :paused="$data.marquee1Pause"
                reverse
              >
                <div id="marquee-image-1" class="marquee-image"></div>
                <div id="marquee-image-5" class="marquee-image"></div>
                <div id="marquee-image-6" class="marquee-image"></div>
                <div id="marquee-image-7" class="marquee-image"></div>
                <div id="marquee-image-8" class="marquee-image"></div>
                <div id="marquee-image-10" class="marquee-image"></div>
                <div id="marquee-image-13" class="marquee-image"></div>
                <div id="marquee-image-15" class="marquee-image"></div>
                <div id="marquee-image-17" class="marquee-image"></div>
                <div id="marquee-image-20" class="marquee-image"></div>
                <div id="marquee-image-22" class="marquee-image"></div>
                <div id="marquee-image-23" class="marquee-image"></div>
              </vue-marquee-slider>
            </div>
          </div>
          <div class="w-100">
            <div class="w-auto" @mouseover="lockMarquee2(true)" @mouseleave="lockMarquee2(false)">
              <vue-marquee-slider
                id="marquee-slider-2"
                class="marquee-slider"
                :space="50"
                :speed="100000"
                :width="400"
                :paused="$data.marquee2Pause"
              >
                <div id="marquee-image-2" class="marquee-image"></div>
                <div id="marquee-image-3" class="marquee-image"></div>
                <div id="marquee-image-4" class="marquee-image"></div>
                <div id="marquee-image-9" class="marquee-image"></div>
                <div id="marquee-image-11" class="marquee-image"></div>
                <div id="marquee-image-12" class="marquee-image"></div>
                <div id="marquee-image-14" class="marquee-image"></div>
                <div id="marquee-image-16" class="marquee-image"></div>
                <div id="marquee-image-18" class="marquee-image"></div>
                <div id="marquee-image-19" class="marquee-image"></div>
                <div id="marquee-image-21" class="marquee-image"></div>
              </vue-marquee-slider>
            </div>
          </div>
        </div>
      </div>

      <div
        @mouseover="$refs.mainNav.setFocus(2)"
        @mouseleave="$refs.mainNav.setNotFocus(2)"
        id="know-how"
        class="su-section-four container"
      >
        <div class="row">
          <div class="col-1 void-column"></div>

          <div class="col-12 col-md-11">
            <h1>Our know-how has been built over three decades</h1>

            <h3>
              and tested across five<br />
              continents, giving us a profound<br />
              understanding of what audiences<br />
              want and what clients need.
            </h3>
            <div>
              <!-- Element to collapse -->
              <b-collapse id="collapse-knowhow">
                <h3>
                  We have the personnel, skills and<br />
                  capacity to create customized<br />
                  entertainment for<br />
                  a wide variety of needs and<br />
                  venues in the tourism industry<br />
                  and beyond.
                </h3>
                <h3>
                  From cruise ships and theme<br />
                  parks to hotels and resorts, from<br />
                  adverts and TV shows to<br />
                  concerts, tours, and corporate<br />
                  events, all the world’s our stage.
                </h3>
              </b-collapse>
              <!-- Using modifiers -->
              <b-button
                v-b-toggle.collapse-knowhow
                @click="collapseButtonKnowhowStatus = (collapseButtonKnowhowStatus + 1) % 2"
                >{{ toggleButtonText[collapseButtonKnowhowStatus] }}</b-button
              >
            </div>
          </div>
        </div>
      </div>

      <div
        @mouseover="$refs.mainNav.setFocus(3)"
        @mouseleave="$refs.mainNav.setNotFocus(3)"
        id="services"
        class="su-section-five container"
      >
        <div
          class="row"
          v-waypoint="{ active: true, callback: onWaypoint2, options: intersectionOptions }"
        >
          <div class="col-1 void-column"></div>

          <div class="col-12 col-md-11 wide-card">
            <div class="row">
              <div class="col-12">
                <div class="player-container pt-3">
                  <vimeo-player
                    video-id="837595132"
                    class="video-player"
                    ref="player2"
                    :options="{
                      portrait: false,
                      byline: false,
                      title: false,
                      muted: true,
                      loop: true,
                      controls: false,
                    }"
                  >
                  </vimeo-player>

                  <div class="video-player-button">
                    <label
                      for="volumeToggle2"
                      class="unmuted toggle-sound"
                      :class="{ sound_mute: mute2 }"
                    >
                      <div class="tooltip--left sound" data-tooltip="Turn On/Off Sound">
                        <div class="sound--icon fa fa-volume-off"></div>
                        <div class="sound--wave sound--wave_one"></div>
                        <div class="sound--wave sound--wave_two"></div>
                        <div class="sound--wave sound--wave_three"></div>
                      </div>
                    </label>
                    <input
                      id="volumeToggle2"
                      type="checkbox"
                      @click="muteToggle2()"
                      v-model="$data.mute2"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 mt-md-5">
                <h1>We offer a full range of entertainment services on a global basis.</h1>
              </div>

              <div class="col-12 mt-md-5">
                <!-- Element to collapse -->
                <b-collapse id="collapse-entertainment">
                  <h3>
                    First up, we create shows, videos<br />
                    and more from start to finish.
                  </h3>
                  <h3>
                    So we draft concepts,<br />
                    storyboards and scripts.<br />
                    We design and produce sets and<br />
                    costumes.<br />
                    We handle sound and lighting.<br />
                    And we provide artistic direction,<br />
                    musical arrangements and<br />
                    choreography.
                  </h3>
                  <h3>
                    But that's not all. We also recruit<br />
                    and manage artists, technicians<br />
                    and other entertainment professionals.<br />
                    So if you're looking<br />
                    for a cast, a writing team,<br />
                    a backstage crew, or an entire production,<br />
                    look no further.
                  </h3>
                </b-collapse>
                <!-- Using modifiers -->
                <b-button
                  v-b-toggle.collapse-entertainment
                  @click="
                    collapseButtonEntertainmentStatus = (collapseButtonEntertainmentStatus + 1) % 2
                  "
                  >{{ toggleButtonText[collapseButtonEntertainmentStatus] }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        @mouseover="$refs.mainNav.setFocus(4)"
        @mouseleave="$refs.mainNav.setNotFocus(4)"
        id="shows"
        class="su-section-six container"
      >
        <div class="row">
          <div class="col-1 void-column"></div>

          <a href="/shows" class="wide-card col-12 col-md-11">
            <div class="row">
              <div class="col-12 main-info">
                <h1>300</h1>
                <span>shows</span>
              </div>

              <div class="col-6 secondary-info">
                <h2>900</h2>
                <span>Live Experiences</span>
              </div>

              <div class="col-6 secondary-info">
                <h2>60</h2>
                <span>
                  performers and<br />
                  technicians
                </span>
              </div>
            </div>

            <div class="row hover-info">
              <div class="col-12">
                <h1>
                  Take a look at<br />
                  our shows
                </h1>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        @mouseover="$refs.mainNav.setFocus(5)"
        @mouseleave="$refs.mainNav.setNotFocus(5)"
        id="contact-us"
        class="su-section-seven container"
      >
        <div class="row">
          <div class="col-1 void-column"></div>
          <div class="col-12 col-md-11">
            <div class="row">
              <div class="col-lg-4 col-12">
                <h1>Ask us anything.</h1>
                <p>
                  Whatever or whoever you're looking for in the entertainment world, get in touch.
                </p>
              </div>
              <form class="col-lg-8 col-12" @submit.prevent="submitEntry">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="name">Complete name*</label>
                      <input
                        id="name"
                        name="name"
                        placeholder="Enter name here..."
                        type="text"
                        class="form-control"
                        v-model="form.name"
                        :disabled="loadingFormRequest"
                        :class="{ 'su-error-field': msg.name.length }"
                        @change="msg.name = ''"
                      />
                      <span class="su-error-field-text" v-if="msg.name.length">{{ msg.name }}</span>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="email">Email*</label>
                      <input
                        id="email"
                        name="email"
                        placeholder="Enter email here"
                        type="text"
                        class="form-control"
                        v-model="form.email"
                        :disabled="loadingFormRequest"
                        :class="{ 'su-error-field': msg.email.length }"
                        @change="msg.email = ''"
                      />
                      <span class="su-error-field-text" v-if="msg.email.length">{{
                        msg.email
                      }}</span>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label for="message">Request*</label>
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Enter your request here..."
                        v-model="form.message"
                        :disabled="loadingFormRequest"
                        :class="{ 'su-error-field': msg.message.length }"
                        @change="msg.message = ''"
                      ></textarea>
                      <span class="su-error-field-text" v-if="msg.message.length">{{
                        msg.message
                      }}</span>
                      <span class="su-info-field-text">Characters: {{ form.message.length }}</span>
                    </div>
                  </div>

                  <div class="col-md-6 col-12 d-flex align-items-center">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input
                          name="terms"
                          id="terms"
                          type="checkbox"
                          class="custom-control-input"
                          value="terms"
                          v-model="form.privacy"
                          :disabled="loadingFormRequest"
                          :class="{ 'su-error-field': msg.privacy.length }"
                          @change="msg.privacy = ''"
                        />
                        <label for="terms" class="custom-control-label">
                          Agree to the <a href="/privacy-policy">Privacy Policy</a>*
                        </label>
                      </div>
                      <div>
                        <span class="su-error-field-text" v-if="msg.privacy.length">{{
                          msg.privacy
                        }}</span>
                      </div>
                      <div class="required_fields_label">
                        <label>*Required fields</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <button
                        :disabled="loadingFormRequest"
                        name="submit"
                        type="submit"
                        class="su-button"
                      >
                        Send request now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        @mouseover="$refs.mainNav.setFocus(6)"
        @mouseleave="$refs.mainNav.setNotFocus(6)"
        id="work-with-us"
        class="su-section-eight container"
      >
        <div class="row">
          <div class="col-1 void-column"></div>
          <div class="col-12 col-md-11">
            <div class="row mb-5">
              <div class="col-12">
                <h1>Join a world<br />class team</h1>
                <h3>
                  Whether you shine on stage or behind the scenes,<br />
                  we'd love to hear from you.
                </h3>
              </div>
            </div>
            <div class="row mb-3 justify-content-center">
              <div class="col-md-6 col-12 mb-3 pr-md-1">
                <a href="/artist" id="artist-card" class="medium-card left"
                  ><h3>ARTIST</h3>
                  <button class="cta-work">join us</button></a
                >
              </div>
              <div class="col-md-6 col-12 mb-3 pl-md-1">
                <a href="/tech" id="tech-card" class="medium-card right"
                  ><h3>TECHNICIAN</h3>
                  <button class="cta-work">join us</button></a
                >
              </div>
            </div>
            <a href="/other" class="other-positions-link">Or apply for another position</a>
          </div>
        </div>
      </div>

      <alert-row ref="alertMsg"></alert-row>

      <footer-row></footer-row>

      <loading-screen ref="loadingScreen"></loading-screen>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import VueMarqueeSlider from 'vue-marquee-slider';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueWaypoint from 'vue-waypoint'
import axios from 'axios'
import VueAxios from 'vue-axios'
import MainNavBar from '@/components/main-nav.vue'
import Footer from '@/components/footer.vue'
import Alert from '@/components/alert.vue'
import Loading from '@/components/loading'


export default Vue.extend({
  data() {
    return {
      marquee1Pause: false,
      marquee2Pause: false,
      collapseButtonEntertainmentStatus: 0,
      collapseButtonKnowhowStatus: 0,
      mute: true,
      mute2: true,
      toggleButtonText : ["Read more", "Close"],
      form:{
        name: '',
        email: '',
        privacy: false,
        message: '',
        page: "Homepage"
      },
      msg:{
        name: '',
        email: '',
        privacy: '',
        message: '',
      },
      responseData: "",
      playerReady: false,
      loadingFormRequest: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
      },
      display: "",
    };
  },
  name: 'home',
  components: {
    'main-nav' : MainNavBar,
    'footer-row' : Footer,
    'alert-row' : Alert,
    'loading-screen' : Loading,
  },
  methods: {
    onWaypoint1 ({ going, direction }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (going === "in") {
        this.$refs.player.play();
      }
      if (going === "out") {
        this.$refs.player.pause();
      }
    },
    onWaypoint2 ({ going, direction }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (going === "in") {
        this.$refs.player2.play();
      }
      if (going === "out") {
        this.$refs.player2.pause();
      }
    },
    lockMarquee1: function(flag) {
      if(this.$data.display !== "xs"){
        this.$data.marquee1Pause = flag;
      }
    },
    lockMarquee2: function(flag) {
      if(this.$data.display !== "xs"){
        this.$data.marquee2Pause = flag;
      }
    },
    toFormData: function(obj) {
      let formData = new FormData();
      for(let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    validateEmail(value){
      return /^[^@]+@\w+(\.\w+)+\w$/.test(value);
    },
    validateName(value){
      return /^[A-Za-z ]+$/.test(value);
    },
    submitEntry: function() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;
      var localError = false;
      this.$data.loadingFormRequest = true;

      if(!this.$data.form.name.length){
        this.$data.msg.name = "Name field can not be void.";
        localError = true;
      }

      else if(!this.validateName(this.$data.form.name)){
        this.$data.msg.name = "Name field not valid. Only letters are admitted.";
        localError = true;
      }

      if(!this.$data.form.email.length){
        this.$data.msg.email = "Email field can not be void.";
        localError = true;
      }
      else if(!this.validateEmail(this.$data.form.email)){
        this.$data.msg.email = "Email format non valid.";
        localError = true;
      }

      if(!this.$data.form.privacy){
        this.$data.msg.privacy = "You must accept the privacy policy.";
        localError = true;
      }

      if(this.$data.form.message.length === 0){
        this.$data.msg.message = "A message is required";
        localError = true;
      }

      if(this.$data.form.message.length > 2500){
        this.$data.msg.message = "Max message length is 2500 characters";
        localError = true;
      }

      if(localError){
        this.$data.loadingFormRequest = false;
        return;
      }

      var formData = this.toFormData(this.$data.form);

      axios.post('/php/showup_form.php',formData)
        .then(response => {
          var infoResponse = response.data;

          this.$refs.alertMsg.showAlert(infoResponse.message);

          if(infoResponse.status) {
            this.$data.form.email = "";
            this.$data.form.name = "";
            this.$data.form.privacy = false;
            this.$data.form.message = "";
          }

          this.$data.loadingFormRequest = false;

        })
        .catch(error => {
          this.$refs.alertMsg.showAlert(error);
          this.$data.loadingFormRequest = false;
        });
    },
    muteToggle() {
      if(this.mute != true){
        this.$refs.player.mute();
      }else{
        this.$refs.player.unmute();
      }
    },
    muteToggle2() {
      if(this.mute2 != true){
        this.$refs.player2.mute();
      }else{
        this.$refs.player2.unmute();
      }
    },
    onReady() {
      setTimeout(() => {
        this.$refs.loadingScreen.isLoading =false; // show loading screen
      }, 2500);
    },
    onResize() {
      let windowWidth = window.innerWidth;
      if (windowWidth < 700) this.$data.display = 'xs'
      if (windowWidth >= 700 && windowWidth < 1200) this.$data.display = 'md'
      if (windowWidth >= 1200) this.$data.display = 'lg'
    }
  },

  mounted() {
    this.$refs.loadingScreen.isLoading = true; // show loading screen
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
});

Vue.use(VueMarqueeSlider);
Vue.use(vueVimeoPlayer);
Vue.use(VueAxios, axios);
Vue.use(VueWaypoint);
</script>
